import React from "react";
import FormContact from "../components/FormContact";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <>
      <FormContact />
      <Footer />
    </>
  );
};

export default Contact;
